import {Box, Card, CardActionArea, CardContent, TextField, Typography} from "@mui/material";
import {useTranslate, useNotify} from "react-admin";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import dataProvider from "../../../components/dataProvider";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import AWS from 'aws-sdk';
import {uuidv4} from "../../../tools/generate";

const TransactionUpload = () => {
    const [companies, setCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [selectedFiles, setSelectedFiles] = useState(null);
    const translate = useTranslate()
    const notify = useNotify()

    useEffect(() => {
        dataProvider.get(`license/companies`)
            .then(({data}) => {
                setCompanies(data.list)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleFileChange = (event) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const handleUpload = async () => {
        if (selectedFiles) {
            AWS.config.update({
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                region: 'eu-central-1'
            });
            const s3 = new AWS.S3();

            for (const file of selectedFiles) {
                const name = uuidv4();
                const params = {
                    Bucket: 'ppos-fe-upload-react',
                    Key: `${selectedCompany.id}/${name}.json`,
                    Expires: 60,
                };
                const uploadUrl = await s3.getSignedUrlPromise('putObject', params);

                try {
                    const res = await axios.put(uploadUrl, file, {
                        headers: {
                            'Content-Type': file.type,
                        }
                    });
                    await axios.post(`http://localhost:8080/api/v1/reprocess/file/upload/company/${selectedCompany.id}/fileName/${name}`)
                    notify('Sikeres feltöltés', {type: "success", autoHideDuration: 3000})
                } catch (err) {
                    console.error('File upload error:', err);
                    notify('Sikertelen feltöltés', {type: "error", autoHideDuration: 3000})
                }
            }
        }
    };

    return (
        <div style={{padding: 20}}>
            <Autocomplete
                value={selectedCompany}
                renderInput={(params) => <TextField {...params} variant="standard"
                                                    label={translate("purchase.company")}/>}
                options={companies}
                getOptionLabel={(option) => option.name || ""}
                onChange={(e, value) => {
                    setSelectedCompany(value)
                }}
                sx={{width: '300px', marginBottom: '16px'}}
            />
            {selectedCompany &&
                <Box>
                    <Card sx={{maxWidth: 500}}>
                        <CardActionArea onClick={() => document.getElementById('fileInput').click()}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {translate("transactionUpload.fileUpload")}
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                    {translate("transactionUpload.fileUploadDescription")}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <input
                            id="fileInput"
                            type="file"
                            accept=".json"
                            multiple
                            style={{display: 'none'}}
                            onChange={handleFileChange}
                        />
                    </Card>
                    {selectedFiles?.map(file => {
                        return (
                            <div>
                                <Typography sx={{marginTop: '20px'}}>
                                    {file.name}
                                    <CancelIcon sx={{color: 'red', cursor: 'pointer'}} onClick={() => setSelectedFiles(
                                        prevFiles => prevFiles.filter(f => f.lastModified !== file.lastModified)
                                    )}/>
                                </Typography>
                            </div>
                        )
                    })
                    }
                </Box>
            }
            {!!selectedFiles &&
                <Button variant="contained"
                        onClick={handleUpload}
                        sx={{marginTop: '20px', color: "white"}}>{translate("transactionUpload.upload")}</Button>}
        </div>
    );
}

export default TransactionUpload;