import hungarianMessages from 'ra-language-hungarian';
import {mergeDeep} from "../tools/mergeDeep";

const missingTranslations = {
    ra: {
        action: {
            confirm: 'Megerősítés',
            search: 'Keresés',
            next: 'Tovább',
            finish: 'Befejezés',
            edit: 'Szerkesztés',
            select_columns: "Oszlopok szűrése",
            clear_array_input: "Összes törlése",
            save: 'Mentés',
            add: 'Hozzáadás',
            modify: "Módosítás"
        },
        page: {
            empty: 'Nincsenek még %{name}.',
            invite: 'Szeretne most hozzáadni egyet?',
        },
        navigation: {
            page_rows_per_page: 'Elemek száma oldalanként',
            skip_nav: "Navigáció elhagyása"
        },
        boolean: {
            null: ' '
        },
        configurable: {
            customize: "Személyreszabás"
        },
        message: {
            clear_array_input: "Biztos szeretné törölni?"
        }
    }
};
const data = {
    common: {
        columns: "Oszlopok",
        columnSelected: "oszlop kiválasztva",
        logout: "Kijelentkezés",
        editProfile: "Profil szerkesztése",
        search: "Keresés",
        cancel: "Mégse",
        yes: "Igen",
        no: "Nem"
    },
    menu: {
        administration: "Adminisztráció",
        priceList: "Árlap",
        storage: "Raktár",
        corporateAdmin: "Corporate Admin"
    },
    products : {
        title: "Termékek",
        id: "ID",
        name: "Név",
        create: "Új termék hozzáadása",
        mainCategory: "NTAK Főkategória",
        subCategory: "NTAK Alkategória",
        vatGroup: "ÁFA csoport",
        price: "Ár",
        productUnits: "Termék kiszerelései",
        quantity: "Mennyiség",
        unit: "Mértékegység",
        qtyUnit: "Kiszerelés",
        defaultPrice: "Alapértelmezett bruttó ár",
        greaterThanZero: "Az értéknek nagyobbnak kell lennie mint 0",
        empty: "Nincsenek még termékek",
        tags: "Címkék",
        filterTags: "Cimkék szűrése",
        dialogTitle: "Törlés megerősítése",
        dialogContextText: "Biztos szeretnéd tötölni a címkét? Az összes termékről el fogja távolítani azt",
        yes: "Igen",
        no: "Nem",
        isTakeawayAllowed: "Elvihető",
        takeawayVatGroup: "Elviteles ÁFA",
        fivePercentText: "Étkezőhelyi vendéglátásban az étel- és a helyben készített, nem alkoholtartalmú italforgalom ÁFÁ-ja 5%",
        wrongQuantityFormat: "Hibás mennyiség. Helyes formátum: 0-nál nagyobb, maximum 9 jegyű szám (opcionálisan maximum két tizedes jegy).",
        wrongPriceFormat: "Hibás ár. Helyes formátum: maximum 9 jegyű szám.",
        showVatHint : "ÁFA segédlet mutatása",
        hideVatHint : "ÁFA segédlet elrejtése",
        nameFilter: "Termék keresése",
        import: "Termékek importálása",
        fileAdd: "Fájl tallózása",
        fileUpload: "Fájl feltöltése",
        fileUploadSuccess: "A fájl sikeresen feltöltve",
        fileExtensionError: "Nem megfelelő a fájl kiterjesztése",
        saveSuccess: "A termékek mentése sikeres",
        saveError: "A termékek mentése sikertelen",
        addNew: "Mentés új termékként",
        created: "Termék sikeresen létrehozva",
        error: "Termék létrehozása sikertelen",
        bulkItem: "Kimért termék",
        ingredients: "Termékreceptúra létrehozása lehetséges a raktár- és receptúra kezelő modul megvásárlásával",
        ingredientsTitle: "Receptúra kezelő",
        continueToBuy: "Tovább a vásárláshoz",
        recipe: "Receptúra",
        recipeTooltip: "rendelkezik raktár tétel összetevővel",
        productImportError: "Nem található mértékegység azonosító: ${productUnitId} ezzel a termékazonosítóval: ${productId}",
        wrongUnit: "Nem létező mértékegység",
        refundBottle: "Visszaváltható palack",
        noRefund: "Nem visszaváltható",
        depositFeeHint: "A betétdíj a beállított árhoz hozzáadódik",
        setSimplifiedBu: "Az üzleti egység egyszerűsített nézetben van, a termékek automatikusan árlapra kerülnek",
        mainCat: "Főkategória",
        subCat: "Alkategória",
        subSubCat: "Al-alkategória",
        depositFeeCount: "Db",
        depositFeePrice: "Ár",
    },
    priceListTemplate : {
        title: "Árlapok kezelése",
        id: "ID",
        name: "Árlap sablon egyedi neve",
        create: "Új Árlap sablon létrehozása",
        createTemplate: "Új sablon",
        created: "Árlap létrehozva",
        createFail: "Hiba az árlap létrehozásánál",
        generalData: "Általános adatok",
        managingProducts: "Termékek hozzáadása / eltávolítása",
        changePrices: "Árak módosítása",
        addProductsToPriceList: "Termékek Hozzáadása árlaphoz",
        noProductInPriceList: "Jelenleg nincs egy termék se az árlistában",
        edit: "Szerkesztés",
        delete: "Törlés",
        saveAndActivate : "Mentés és Aktiválás",
        saveAsTemplate : "Mentés",
        saveAsInstance : "Árlap mentés",
        successfulSave: "Sikeres mentés",
        draft: "Vázlat",
        activated: "Aktivált",
        uniqueNameRequired: "Új egyedi név megadása kötlező",
        state: "Állapot",
        price: "Ár",
        modify: "Módosítás",
        cancel: "Mégse",
        empty: "Nincsenek még árlapok",
        nameShort: "Árlapok",
        createPriceList: "Új árlap létrehozása",
        createdAt: "Létrehozás dátuma",
        template: "Árlap sablon",
        templates: "Árlap sablonok",
        priceHelperText: "Maximum 9 jegyű szám",
        tooMuch: "A termékek száma meghaladja a 2000-et, kerjük használj szűrőket",
        comment: "Megjegyzés",
        tooltip1: "kis képernyő: 3x5",
        tooltip2: "közepes képernyő: 4x7",
        tooltip3: "nagy képernyő: 6x7",
    },
    businessUnits : {
        title: "Üzleti egységek",
        id: "ID",
        name: "Név",
        create: "Új Üzleti egység létrehozása",
        publicDomainName: "Közterület neve",
        publicDomainType: "Közterület jellege",
        number: "Házszám",
        settlement: "Település",
        zipCode: "Irányítószám",
        address: "Cím",
        empty: "Nincsenek még üzleti egységei",
        company: "Cég",
        wrongZipCodeFormat: "Rossz irányítószám formátum (helyes: 4-10 szám)",
        ntak: "NTAK regisztrációs szám",
        integration: "NTAK integráció",
        generalData: "Általános adatok",
        ntakData: "NTAK adatok",
        ntakNumber: "NTAK regisztrációs szám",
        generateCSR: "CSR validációs fájl generálása",
        certUpload: "Tanúsítvány feltöltése",
        certAdd: "Tanúsítvány tallózása",
        fileSelectError: "A tanúsítvány kiválasztása sikertelen",
        fileUploadError: "A tanúsítvány feltöltése sikertelen",
        fileUploadSuccess: "A tanúsítvány sikeresen feltöltve",
        download: "CSR fájl letöltése",
        loading: "CSR fájl generálása folyamatban, kérem várjon",
        csrGenerateError: "A fájl létrehozása nem sikerült",
        ntakDataProvision: "NTAK adatszolgáltatás",
        ntakChangeHint: "Az adatszolgáltatás ki/be-kapcsolása csak akkor lehetséges, ha nincs nyitott munkanap az üzleti egységben",
        certHint: 'A legutoljára generált és mentett CSR tartalmazza az érvényes adatokat. Amennyiben új CSR-t készít, azt fel kell tölteni az ntak.hu “Tanúsítványkezelés” menüpontjában.',
        certExpired: 'A legutolsó feltöltött tanúsítvány lejárati ideje: ',
        ntakCsrHint: "CSR fájl generálása előtt mentse az adatokat",
        companyChangeHint: "Cég váltása csak akkor lehetséges, ha nincs nyitott munkanap az üzleti egységben",
        isSimplified: "Egyszerűsített nézet (Termékek automatikus árlapra küldése)",
        simplifiedFlag: "Egyszerűsített nézet",
        ntakNumberH: "Az NTAK regisztrációs szám, akkor mentődik, ha CSR fájlt generál",
        success: "Sikeres mentés!",
        error: "Hiba a mentés során",

    },
    technicalUsers : {
        title: "Felhasználók",
        id: "ID",
        name: "Név",
        create: "Új technikai felhasználó felvétele",
        pinCode: "Pin kód",
        pinCodeCreate: "Pin kód (4 jegyű)",
        pinCodeAgain: "Pin kód újra (4 jegyű)",
        technicalUserData: "Technikai felhasználó adatai",
        technicalUserRole: "Felhasználó szerepköre",
        posSets: "Szerepkörhöz tartozó csoportok",
        resetPIN: "PIN kód csere",
        userTypeFilter: "Felhasználó típus szűrő",
        technical: "Technikai felhasználó",
        registered: "Regisztrált felhasználó",
        type: "Felhasználó Típusa",
        validation :{
            nameNull: "A név mező kitöltése kötelező",
            pinDontMatch: "A két PIN kód nem egyezik meg",
            pinWrongLength: "A PIN kód 4 hosszú kell legyen",
            pinNotANumber: "A PIN kód csak számokat tartalmazhat",
            noSelectedPosSet: "Válasszon ki legalább egy hierarchia elemet",
            hpPosUserIdWrongLength: "A HelloPay POS felhasználónak legalább 4 és maximum 20 karaternek kell lennie",
            hpPosUserIdNotANumber: "A HelloPay POS felhasználó csak számokat tartalmazhat"
        },
        helloPayPosUser: "HelloPay POS felhasználó",
        helloPayPosUserHint: "HelloPay terminál belépési azonosító. Minimum 4 számjegy",
        inviteUser: "felhasználó meghívása",
        company: "Cég kiválasztása",
        businessUnit: "Üzleti egység kiválasztása",
        privilegesAdmin: "Admin jogosultságok",
        privilegesApi: "Pincér jogosultságok",
        limit: "Nem lehetséges több felhasználó felvétele",
        buy: "További felhasználók meghívására, valamint technikai felhasználók létrehozására van lehetőség a felhasználó modul megvásárlásával",
        error: "Ellenőrizze a jogosultságát, majd próbálja újra",
    },
    groups: {
        title: "Csoportok kezelése",
        cantDrag: "Nem helyzehető át alacsonyabb hierachia szintre",
        cantUpdate: "Nem helyzehető át, mert nincs hozzá jogosultsága",
        cantDragWithOpenShift: "Nem helyezhető át nyitott műszakkal",
        cantDragBelowCompany: "Nem helyezhető át cég alá",
        editNode: "Csoport szerkesztése",
        addChild: " hozzáadása",
        name: "Név",
        type: "Csoport típusa",
        pos: "POS",
        bar: "Pult",
        area: "Terület",
        company: "Cég",
        businessUnit: "Üzleti egység",
        editSuccessful : "Sikeres módosítás, a változások érvényesítéséhez kattintson a mentés gombra",
        editError : "A csoport módosítása sikertelen volt",
        deleteSuccessful : "Elem törlve",
        poss: "POS-ok",
        edit: "Kattintson a szerkeszteni kivánt csoportra",
        deleteUnsuccessful: "Nem törölhető csoport amg tartozik hozzá POS",
        expandAll: "Összes csoport kinyitása",
        collapseAll: "Összes csoport bezárása",
        add: "Hozzáadás",
        contact: "További POS-ok beszerzéséhez",
        click: "kattintson ide",
        storage: "Raktár",
        addStorage: "Raktár hozzáadása",
        selectStorage: "Raktár kiválasztása",
        buy: "Integrált eszközök használatához, kedvezmény, szervízdíj és fizetési módok konfigurálásához az integrációs modul megvásárlása szükséges",
    },
    activePosSets: {
        title: "Üzleti egység választó",
        posSets: "Üzleti egységek",
        change: "Üzleti egység kiválasztása",
        noActive: "Nincsenek még üzleti egységei",
        setActivePosSet: "Válasszon egy aktív üzleti egységet",
        changeSuccess: "Aktív üzleti egység sikeresen kiválasztva"
    },
    priceListToPosSets: {
        title: "Árlap leküldése",
        priceLists: "Árlapok",
        priceList: "Árlap",
        empty: "Nem található árlap",
        currentPriceList : "Jelenlegi árlap",
        changePriceList: "Árlap csere",
        newPriceList: "Új árlap",
        updated: "Árlap frissítve",
        error: "Hiba az árlap frissítés során"
    },
    device: {
        title: "Eszközök kezelése",
        empty: "Nincsenek még eszközök",
        posId: "POS ID",
        posName: "POS Név",
        deviceId: "Eszköz ID",
        deviceName: "Eszköz Név",
        remove: "Leválasztás POS-ról",
        shiftOpen: "Jelenleg van nyitott műszak",
        cantRemove: "Nem lehet leválasztani az eszközt",
        detachAreYouSure: "Biztosan leválasztja az eszközt",
        removeShort: "Leválasztás",
        priceListCheckTime: "Utolsó árlap szinkronizáció",
        configCheckTime: "Utolsó konfiguráció lekérése",
        lastCommunication: "Utolsó kommunikáció időpontja"
    },
    pos: {
        title: "POS-ok létrehozása",
        add: "Új POS felvétele",
        name: "POS név",
        edit: "Módosítás",
        cancel: "Mégse"
    },
    report: {
        title: "Jelentések",
        add: "Jelentés létrehozása",
        groupingByWarehouse: "Raktár szerinti csoportosítás",
        type: "Jelentés típus",
        created: "Jelentés sikeresen elkészült",
        download: "Letöltés",
        reset: "Visszaállítás",
        noSelectedPosSet: "Válasszon ki legalább egyet",
        date:{
            start: "Időszak kezdete",
            end: "Időszak vége",
            endBeforeStartError: "A vége dátum kisebb mint a kezdet"
        }
    },
    quickMenu: {
        title: "Gyors menü",
        add: "Termék hozzáadása a gyorsmenühöz",
        addShort: "Hozzáadás",
        success: "A terméket sikeresen hozzáadtad a gyorsmenühöz",
        full: "A gyorsmenüben már nincs szabad hely, nem tudtad hozzáadni a gyorsmenühöz",
        error: "Hiba a hozzáadás során, kérlek próbáld újra",
        addText: "Termék beszúrásához kattintson a kívánt cellába",
        choosePriceList: "Válasszon árlapot, utána szerkesztheti",
        width: "Szélesség",
        height: "Magasság",
        tooMuch: "A termékek száma meghaladja a 2000-et, kerjük szűkítse név szerint",
    },
    priceListsOfPos: {
        title: "POS-on levő árlapok",
        id: "POS ID",
        name: "POS Név",
        priceListId: "Árlap Id",
        priceListName: "Árlap Név",
        priceListValidFrom: "Érvényesség kezdete",
        priceLists: "Árlapok",
        open: "Megnyitás",
        search : "Keresés",
        createdAt: "Létrehozás dátuma"
    },
    company: {
        title: "Cég",
        id: "ID",
        name: "Név",
        create: "Új cég létrehozása",
        publicDomainName: "Közterület neve",
        publicDomainType: "Közterület jellege",
        number: "Házszám",
        settlement: "Település",
        zipCode: "Irányítószám",
        state: "Megye",
        country: "Ország",
        address: "Cím",
        empty: "Nincsenek még cégei",
        taxNumber: "Adószám",
        groupTaxNumber: "ÁFA csoportazonosító száma",
        companyRegistrationNumber: "Cégjegyzékszám",
        euTaxNumber: "Közösségi adószám",
        contactName: "Kontakt személy",
        contactEmail: "Kontakt email",
        contactPhone: "Kontakt telefonszám",
        phonePrefix: "Előhívó szám",
        general: "Általános adatok",
        contacts: "Kapcsolat",
        created: "Cég sikeresen létrehozva",
        wrongZipCodeFormat: "Rossz irányítószám formátum (helyes: 4-10 szám)",
        wrongTaxNumberFormat: "Csak érvényes adószám fogadható el! Helyes formátum: xxxxxxxx-y-zz",
        wrongEmailFormat: "Hibás email formátum",
        mustHaveTaxNumber: "Az adószám, vagy az EU adószám megadása kötelező",
        companyNameRequired: "A cég nevének kitöltése kötelező",
        zipCodeRequired: "Az irányítószám kitöltése kötelező",
        stateRequired: "A megye kitöltése kötelező",
        countryRequired: "Az ország kitöltése kötelező",
        settlementRequired: "A település kitöltése kötelező",
        publicDomainNameRequired: "A közterület nevének kitöltése kötelező",
        publicDomainTypeRequired: "A közterület jellegének kitöltése kötelező",
        contactNameRequired: "A kapcsolattartó nevének kitöltése kötelező",
        contactEmailRequired: "A kapcsolattartó email címének kitöltése kötelező",
        contactPhoneRequired: "A kapcsolattartó telefonszámának kitöltése kötelező",
        numberRequired: "A házszám kitöltése kötelező",
    },
    dashboard:{
        title: "Vezérlőpult",
        activeWorkday: "Aktív munkanap",
        dateRangeFilter: "Időszak szűrő",
        date:{
            start: "Időszak kezdete",
            end: "Időszak vége",
            endBeforeStartError: "A vége dátum kisebb mint a kezdet"
        },
        refresh: "Frissítés",
        filter: "Szűrés",
        sumPurchase: "Vásárlás Szum",
        sumTip: "Borravaló Szum",
        sumCanceled: "Sztornó Szum",
        total: "Összes Szum",
        averageCartValue: "Átlagos kosárérték",
        averageTip: "Átlagos borravaló",
        averageTipPercent: "Átlagos borravaló százalék",
        countPurchase: "Tranzakció száma",
        product: "TOP termékek",
        bar: "TOP 10 pult",
        distributionRatio: "Tranzakció eloszlási arány",
        cash: "Készpénz",
        helloPay: "HelloPay",
        card: "Bankkártya",
        otp: "Bankkártya",
        otp_manual: "Bankkártya kézi bevitel",
        mixed: "Vegyes",
        eurCash: "Készpénz EUR (HUF-ban kifejezve)",
        szepCard: "SZÉP kártya",
        bankTransfer: "Átutalás",
        helloPayVip: "HelloPay VIP",
        other: "Egyéb",
        voucher: "Utalvány",
        roomLoan: "Szobahitel",
        fiveMin: "5 perc",
        fifteenMin: "15 perc",
        oneHour: "1 óra",
        trafficCount: "Forgalom - darabszám",
        trafficSum: "Forgalom - összesítve",
        endBeforeStartError: "A vége dátum kisebb mint a kezdet",
        moreThanSevenDayInterval: "Az intervallum nem lehet nagyobb 7 napnál",
        endIsTooMuch: "A vége dátum túl nagy",
        trxCount: "Tranzakció db",
        tip: "Borravaló",
        noWorkday: "Nincs nyitott munkanap",
        name: "Név",
        piece: "Darab",
        sum: "Összeg",
        selectCompanyOrBu : "Válasszon céget vagy üzleti egységet",

    },
    unit:{
        pieces: "db",
        percent: "%",
        huf: "Ft"
    },
    profile:{
        title: "Profil szerkesztése",
        inviteTitle: "Profil meghívása",
        username: "Email cím",
        givenName: "Keresztnév",
        familyName: "Vezetéknév",
        phone: "Telefon",
        pin: "PIN",
        helloPayPosUser: "HelloPay POS user azonosító",
        helloPayPosUserHint: "HelloPay terminál belépési azonosító. Minimum 4 számjegy",
        phoneHint: "Formátum: +xxxxxxxxxxx pl:+36201234567",
        pinHint: "Csak 4 darab számjegyet tartalmazhat",
        success: "Adatok elmentve",
        loadingError: "Hiba a betöltés során",
        savingError: "Hiba a mentés során",
        noSelectedPosSet: "Válasszon ki legalább egyet",
        noSelectedPosSetOrPrivilege: "Válasszon ki legalább egy szerepkört és egy helyet",
        inviteButtonLabel: "Meghívó kiküldése",
        inviteSuccessfullySend: "Meghívó kiküldve a megadott email címre",
        invSuccessfullyAccepted: "Meghívó sikeresen elfogadva",
        invError: "Hiba a meghívó elfogadása közben",
        save: "Mentés",
    },
    config:{
        title: "POS konfiguráció",
        config: "Konfiguráció",
        currentConfig: "Jelenlegi konfiguráció",
        updated: "Konfiguráció frissítve",
        error: "Hiba a konfiguráció mentése során",
        paymentMethods: "Fizetési módok",
        CASH: "Készpénz",
        CARD: "Bankkártya",
        CARD_MANUAL: "Bankkártya kézi bevitel",
        VOUCHER: "Utalvány",
        SZEP_CARD: "SZÉP kártya (terminál)",
        SZEP_CARD_MANUAL: "SZÉP kártya (manuális)",
        VAT_INVOICE_CASH: "Áfás számla (készpénz)",
        STAFF_CONSUMPTION: "Személyzeti fogyasztás",
        EUR_CASH: "Készpénz EURO",
        ROOM_LOAN: "Szobahitel",
        BANK_TRANSFER: "Átutalás",
        HELLO_PAY: "HelloPay",
        HELLO_PAY_VIP: "Áfás számla (bankkártya)",
        MIXED: "Vegyes",
        VAT_INVOICE_CARD: "Áfás számla (kártya)",
        OTHER: "Egyéb",
        paymentDevice: "Fizetési eszköz",
        none: "Nincs",
        helloPayTerminal: "HelloPay terminál",
        soft_pos: "SoftPOS",
        notIntegrated: "Nem integrált",
        clientDisplay: "Vevőkijelző (Ügyfélmonitor)",
        helloPayCD: "HelloPay Ügyfélmonitor",
        integratedCD: "Integrált Ügyfélmonitor",
        taxPrinter: "NAV",
        taxPrinterConnected: "Adóügyi nyomtatóval összekötve",
        secondScreenVideo: "Videó lejátszás engedélyezése",
        cashRegisterConnected: "Pénztárgéppel összekötve",
        discount: "Kedvezmény",
        discountAllowed: "Kedvezmény engedélyezett",
        serviceFeePercent: "Szervízdíj (0-100%)",
        maxDiscountPercent: "Kedvezmény maximális értéke (0-100%)",
        pickMinimumOne: "Jelöljön be legalább 1 fizetési módot!",
        hideItemsFromCustomer: "Felütött termékek elrejtése",
        buy: "Integrált eszközök használatához, kedvezmény, szervízdíj és fizetési módok konfigurálásához az integrációs modul megvásárlása szükséges",
        clientTip: "Borravaló áfa szétbontása KP",
        clientTipCard: "Borravaló áfa szétbontása Kártya",
        onReceipt: "Nyugtán szerepeljen",
        directTip: "Direkt borravaló",
        incomeReceiptPrint: "Bevételi pénztárbizonylat nyomtatása",
        mixedBreakdown: "Kevert borravaló szétbontása",
        tableManagement: "Asztalkezelés",
        mixedField: "Direkt borravaló mértéke (0-100%)",
        mixedFieldTooltip: "A megadott x% - direkt borravaló, 100-x% - ról bevételi pénztárbizonylatot nyomtat",
        maxStaffDiscountPercent: "Személyzeti kedvezmény maximális értéke (0-100%)",
        staffAllowed: "Személyzeti fogyasztás engedélyezett",
        staffTooltip: "Személyzeti fogyasztás esetén a tranzakció nem lesz beküldve az NTAK rendszerbe."

    },
    rMSErrorMessagesList:{
        title: "NTAK hibaüzenetek",
        id: "ID",
        empty: "Nincsenek hibás NTAK üzenetek",
        processId: "NTAK feldolgozásazonosító",
        status: "Státusz",
        trxType: "NTAK tranzakció típus",
        trxId: "Tranzakció ID",
        stornoTrxId: "Sztornó tranzakció ID",
        workdayId: "Munkanap ID",
        rmsId: "NTAK rendelésazonosító",
        closedDay: "Adott napon zárva",
        sendingTime: "Küldés időpontja",
        transactionTimestamp: "Tranzakció idő",
        workdayOpenedAt: "Munkanap nyitás",
        periodicClosedAt: "Technikai zárás időpontja",
        TO_BE_SENT: "Küldendő",
        SENT: "Elküldve",
        ERROR_IN_SENDING: "Küldés közbeni hiba",
        RECEIVED: "Befogadott",
        ERROR_IN_CHECKING: "Hiba ellenőrzéskor",
        TO_BE_SENT_AGAIN: "Újraküldendő",
        FULLY_WRONG: "Teljesen hibás",
        PARTIALLY_SUCCESSFUL: "Részben sikeres",
        FULLY_SUCCESSFUL: "Teljesen sikeres",
        NORMAL: "Normál",
        STORNO: "Sztornó",
        WORKDAY: "Munkanap",
        CLOSED_DAY: "Adott napon zárva",
        PERIODIC_CLOSED_DAY: "Technikai zárás"
    },
    warehouse:{
        title: "Raktár tételek",
        empty: "Nincsenek elérhető funkciók",
        id: "ID",
        name: "Név",
        items: "Raktár tételek",
        create: "Új tétel hozzáadása",
        nameFilter: "Tétel keresése",
        productUnits: "Tétel kiszerelései",
        packagingUnit: "Kiszerelési egység",
        quantity: "Mennyiség",
        qtyUnit: "Kiszerelés",
        tags: "Címkék",
        mainProductGroup: "Termékfőcsoport",
        filterTags: "Cimkék szűrése",
        filterMainProductGroup: "Termékfőcsoport keresése",
        unit: "Mértékegység",
        greaterThanZero: "Az értéknek nagyobbnak kell lennie mint 0",
        wrongQuantityFormat: "Hibás mennyiség. Helyes formátum: 0-nál nagyobb, maximum 9 jegyű szám (opcionálisan maximum két tizedes jegy).",
        unitError: "Egy mértékegység csak egy kiszereléshez rendelhető",
        circleError: "A mértékegységeknek növekvő sorrendben kell lenniük",
        vatGroup: "ÁFA csoport",
        isTakeawayAllowed: "Elvihető",
        takeawayVatGroup: "Elviteles ÁFA",
        fivePercentText: "Étkezőhelyi vendéglátásban az étel- és a helyben készített, nem alkoholtartalmú italforgalom ÁFÁ-ja 5%",
        unitDifference: "Különböző típusú mértékegységek",
        defaultUnit: "Standolási kiszerelés",
        defaultWarehouseUnit: "Készletezési kiszerelés",
        fieldRequired: "Kötelező kitöltendő mezők!",
        serverError: "Hiba történt a kommunikáció során",
        siUnitError: "Kiszerelési egység nem lehet SI mértékegység",
        duplicateNameError: "Kiszerelési egységnek egyedinek kell lennie",
        unitAndQuantityError: "Mértékegység és mennyiség csak akkor lehet üres ha egyik sincs kitöltve",
        duplicateUnitType: "Mértékegység nem lehet azonos típusú",
        buy: "Raktárkezelés, receptúra kezelés, illetve az ehhez tartozó funkciókhoz való hozzáférés a raktár- és receptúra kezelő modul megvásárlásával lehetséges",
        active: "Aktív tételek",
        inactive: "Inaktív tételek",
        all: "Összes tétel",
        products: "Tételek",
        available: "Elérhető tétel",
    },
    storage:{
        title: "Raktárak kezelése",
        id: "ID",
        name: "Raktár neve",
        phone: "Telefonszám",
        contact: "Raktárvezető neve",
        create: "Új raktár felvétele",
        address: "Cím",
        phonePrefix: "Előhívó",
        email: "Kontakt email",
        isLogistics: "Logisztikai raktár",
        isActive: "Aktív raktár"
    },
    suppliers:{
        title: "Szállítók",
        id: "ID",
        name: "Név",
        create: "Új szállító hozzáadása",
        address: "Cím",
        taxNumber: "Adószám",
        phone: "Telefonszám",
        email: "E-mail",
        contact: "Kapcsolattartó neve",
        phonePrefix: "Előhívó szám",
        empty: "Nincsenek még szállítók",
        saveSuccess: "A szállítók mentése sikeres",
        saveError: "A szállítók mentése sikertelen",
        import: "Szállítók importálása",
        supplierIn: "Bevételezési árlista",
        supplierOut: "Kivételezési árlista",
        warehouseProducts: "Raktár tételek",
        warehouseProduct: "Raktár tétel",
        unit: "Mértékegység",
        price: "Nettó ár",
        delete: "Törlés",
        wrongImportedFile: "Nem megfelelő fájl"
    },
    modifiers:{
        addIngredient: "Összetevő(k) hozzáadása",
        ingredient: "Összetevő",
        quantity: "Mennyiség",
        unit: "Mértékegység",
        hideModifier: "Módosító elrejtése",
        addModifier: "Módosító hozzáadása",
        modifier: "Módosító",
        priceDifference: "Árkülönbözet",
        delete: "Törlés",
        tags: "Címkék",
        product: "Raktár tétel",
        active: "Aktív",
    },
    operations:{
        title: "Raktár műveletek",
        empty: "Nincs megjeleníthető raktárművelet",
        id: "ID",
        create: "új raktár művelet hozzáadása",
        type: "Raktár művelet típusa *",
        date: "Dátum",
        source: "Forrás",
        destination: "Cél",
        invoice: "Számla száma",
        comment: "megjegyzés",
        sum: "összesen (br)",
        fromSupplier: "Bevételezés szállítótól",
        move: "Átvételezés raktárak között",
        toSupplier: "Kivételezés szállító felé",
        disposal: "Selejt",
        waste: "Selejtezés",
        switchSupplier: "Szállító választása *",
        switchWarehouse: "Raktár választása *",
        product: "Raktár tétel",
        quantity: "Mennyiség",
        unit: "Mértékegység",
        netto: "Nettó egységár",
        brutto: "Bruttó egységár",
        vat: "ÁFA",
        sumNetto: "Nettó összeg",
        sumBrutto: "Bruttó összeg",
        delete: "Törlés",
        discount: "Nettó kedvezmény",
        discountPercent: 'Kedvezmény "%"',
        total: "Összesen",
        addProduct: "Raktár tétel hozzáadása",
        negative: "A végösszeg nem lehet negatív",
        stock: "Készlet",
        sourceStock: "Forrás raktár készlete",
        targetStock: "Cél raktár készlete",
        alert: "Ha módosítja a Forrás/cél szállítót, akkor az alul kitöltött táblázatban az adott szállítói árak felülírják a jelenleg beírt árakat. Biztosan folyatatja?",
        continue: "Folytatás",
        cancel: "Mégse",
        dialogTitle: "Figyelmeztetés",
        import: "Raktár műveletek importálása"
    },
    transactionUpload:{
        title: "Tranzakciók feltöltése",
        fileUpload: "Fájl kiválasztása",
        fileUploadDescription: "A tranzakciók feltöltéséhez kérjük kattintson ide, majd válassza ki a megfelelő fájlt!",
        upload: "Feltöltés",
        chooseCompany: "Válassza ki a céget",
    },
    inventory:{
        title: "Leltár",
        empty: "Nincs megjeleníthető leltár",
        id: "ID",
        create: "Leltár készítése",
        type: "Rész leltár",
        switchWarehouse: "Raktár kiválasztás",
        date: "Leltárazás időpontja",
        comment: "Megjegyzés",
        warehouse: "Raktár",
        product: "Raktár tétel név",
        unitName: "Kiszerelés",
        stock: "Leltár előtti készlet",
        quantity: "Leltárazott készlet",
        difference: "Különbség",
        search: "Raktár tétel keresése",
        surplus: "Többlet:",
        shortage: "Hiány:",
        chosenWarehouse: "Kiválasztott raktár:",
        items: "Raktár tételek",
        sumNetto: "Összesített nettó összeg",
        sumBrutto: "Összesített bruttó összeg",
        alert: "Figyelmeztetés",
        dialogContent: "Leltárban nem szerepelhet negatív érték. A negatív értékkel szereplő tételek automatikusan 0 készletszintre lesznek leltárazva.",
        continue: "Folytatás",
        cancel: "Mégse",
        success: "A leltár sikeresen elkészült",
        error: "Hiba a leltár készítése során",
        invalidNumber: "Hibás számformátum",
    },

    stockReport:{
        title: "Készletváltozások",
        warehouse: "Raktárak választása",
        sup: "Szállítók választása",
        dateStart: "Időszak kezdete",
        dateEnd: "Időszak vége",
        name: "Név",
        delete: "Törlés",
        create: "Excel készítése",
        all: "Összes kiválasztása",
        wh: "Raktár",
        supplier: "Szállító",
        type: "Típus",
    },

    stockpile:{
        title: "Raktárkészlet",
        id: "ID",
        warehouseName: "Raktár tétel",
        warehouse: "Raktárak választása",
        sup: "Szállítók választása",
        tags: "Címkék",
        date: "Dátum",
        name: "Név",
        delete: "Törlés",
        create: "Lista készítése",
        packaging : "Kiszerelés",
        stock: "Készlet",
        netAveragePrice: "Nettó átlagár",
        grossAveragePrice: "Bruttó átlagár",
        lastNetPrice: "Utolsó bevételezés nettó ára",
        stockValue: "Nettó készlet érték",
        aggregatedStockValue: "Összesített nettó készlet érték",
        exportStockpile: "Exportálás",
        all: "Összes kiválasztása",
        wh: "Raktár",
        supplier: "Szállító",
        type: "Típus",
    },
    purchase:{
        title: "Vásárlás",
        lite: "LITE",
        standard: "STANDARD",
        professional: "PROFESSIONAL",
        proPlus: "PRO PLUS",
        unique: "EGYEDI",
        db: "db",
        sum: "Összesen:",
        free: "Ingyenes modul",
        user: "Felhasználó modul",
        tech: "Technikai felhasználók száma:",
        int: "Integrációs modul",
        warehouse: "Raktár kezelő modul",
        report: "Jelentés modul",
        pay: "Fizetés",
        license: "Megvásárolni kívánt licenszek száma:",
        company: "Cég *",
        quantity: "Mennyiség",
        price: "Ár",
        liteCount: "Lite licenszek száma nem haladhatja meg az 1-et",
        date: "Dátum",
        save: "Mentés",
        success: "Sikeres vásárlás",
        error: "Hiba a vásárlás során",
        support: "Support",
        continue: "Tovább a véglegesítéshez",
        step1: "Csomag kiválasztása",
        step2: "Összegzés és fizetés",
        details: "A licenszek száma határozza meg, hogy egyidejűleg mennyi eszközön használható a rendszer.",
        noCompany: "Licenszek vásárlásához céges adminisztrációs jogkör szükséges.",
        basicSupportTooltip: "Általános support: Emailen keresztül történő support, 2 napon belüli válaszadás, intézkedés.",
        dailySupportTooltip: "Napközbeni support: Telefonos support 3 órán belüli válaszadás, távoli intézkedés. Elérhető 08:00 és 16:00 között.",
        fullSupportTooltip: "Teljes körű support: 0-24-es support, 3 órán belüli válaszadás és távoli intézkedés. Helyszíni javítás 6 órán belül. Hardverjavítás/csere 2 munkanapon belül.",
        purchaseInProgress: "Már folyamatban van egy vásárlás ezen a cégen",
        continueOnSimple: "Fejezze be, vagy szakítsa meg a vásárlást a SimplePay oldalán",
        continueOnSimpleButton: "Tovább a SimplePay oldalra",
        voucher: "Kupon",
        validateVoucher: "Kupon ellenőrzése",
        voucherNotFound: "A kupon nem található",
        usedVoucher: "A kupon már felhasználásra került",
        voucherError: "Hiba a kupon ellenőrzése során",
        voucherSuccess: "Felhasználható kupon",
        badVoucher: "A kupon nem használható fel a választott csomaghoz",
    },
    purchaseHistory:{
        title: "Vásárlási előzmények",
        package: "Csomag neve",
        id: "ID",
        purchaseDate: "Vásárlás dátuma",
        expirationDate: "Lejárat dátuma",
        cancel: "Sztornó",
        canceled: "Sztornózva",
        dialogTitle: "Vásárlás sztornózása",
        dialogContent: "Biztosan szeretné sztornózni a vásárlást?",
        dialogCancel: "Mégse",
        dialogConfirm: "Sztornó",
        success: "Sikeres sztornó",
        error: "Hiba a sztornó során",
        inProgress: "Már folyamatban van egy vásárlás ezen a cégen, kérjük próbálja újra később.",
    },
    redirectToPurchase:{
        title: "Nincs megfelelő licensze a funkció használatához",
        buy: "Kattintson az alábbi gombra a csomagok megtekintéséhez",
        continueToBuy: "Tovább a vásárláshoz",
    },
    resignation:{
        title: "Előfizetés lemondása",
        content: "Amennyiben biztosan szeretné lemondani jelenlegi előfizetését, kérjük, hogy kattintson a 'Lemondás' gombra.",
        resignation: "Lemondás",
        success: "Sikeres lemondás",
    },
    shiftClosure: {
        title: "Műszakzárás",
        closing: "Műszak lezárása",
        warning: "Az itt végrehajtott műszakzárás inkonzisztens állapotba hozhatja az eszközt, tranzakciós adatok veszhetnek el. Biztosan lezárja a műszakot?",
        posId: "POS ID",
        posName: "POS név",
        buId: "Üzleti egység ID",
        buName: "Üzleti egység név",
        companyId: "Cég ID",
        companyName: "Cég név",
        edit: "Módosítás",
        cancel: "Mégse",
        success: "Sikeres műszakzárás",
        error: "Hiba a műszakzárás során",
    },
    finance: {
        title: "Pénzügy",
        all: "Összes kiválasztása",
        companies: "Cégek választása",
        id: "id",
        name: "Cégnév",
        licenseName: "Licensz",
        supportName: "Support",
        licenseCount: "Vásárolt licenszek",
        totalAmount: "Összesen €",
        purchaseDate: "Vásárlás dátuma",
        expirationDate: "Lejárat dátuma",
        stornoDate: "Sztornó dátuma",
        paymentStatus: "Fizetési státusz",
        invoiceNumber: "Számla száma",
        trxId: "Tranzakció ID",
        address: "Cím",
        email: "E-mail",
        taxNumber: "Adószám",
        groupTaxNumber: "Csoportos adószám",
        selectCompany: "Válasszon céget",
        dateStart: "Időszak kezdete",
        dateEnd: "Időszak vége",
        listTitle: "Nincsenek még Tranzakciók",
        fixPrice: "Fix ár",
        discountPercent: "Kedvezmény %",
        voucherType: "Voucher típusa",
        voucherCode: "Voucher kód",
    },
    voucher: {
        title: "Voucher generálás",
        create: "Új voucher generálása",
        id: "ID",
        name: "Név",
        success: "Voucherek generálása sikeres",
        error: "Hiba a voucher generálása során",
        discountPercent: "Kedvezmény %",
        fixPrice: "Fix ár",
        licenseCount: "Licenszek száma",
        validForCycle: "Érvényességi idő",
        validFrom: "Érvényesség kezdete",
        validTo: "Érvényesség vége",
        voucherType: "Voucher típusa",
        createName: "Voucher neve",
        maxUsageCount: "Felhasználhatóság száma",
        discount: "Kedvezmény típusa",
        license: "Licensz",
        LicenseCount: "Generált voucherek száma",
        custom: "Custom Voucher kód",
        yes: "Igen",
        no: "Nem",
        voucherCode: "Voucher kód",
        generate: "Generálás",
        codeLength: "Kód hossza 10-20 karakter",
        codeCount: "Max hány licenszre alkalmazható",
        maxUsageTooltip: "Egy adott vouchert hány alkalommal lehet felhasználni",
        validForCycleTooltip: "hányszor 30 napig érvényesek a voucherek",
        licenseTooltip: "Mely csomagokra érvényesek a generálandó voucherek",
        dialogTitle: "Figyelméztetés",
        alert: "Biztosan törölni szeretné a kiválasztott vouchereket?",
        continue: "Folytatás",
        cancel: "Mégse",
        required: "Kötelező mező",
        customTip: "Csak ékezet nélküli karaktereket és számokat tartalmazhat",
    },
    summary:{
        title: "Összegzés",
        email: "E-mail",
        simpleAszf: "Tudomásul veszem, hogy a Pink POS Kft (2120 Dunakeszi, Kismarton utca 19.) adatkezelő által az admin.pinkpos.com felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: a vásárló e-mail címe, számlázási neve, ország, megye, város, irányítószám, és címe.  Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg: ",
        simpleLink: "http://simplepay.hu/vasarlo-aff",
        company: "Cég",
        payable: "Fizetendő",
        pay: "Fizetés",
        cancel: "Mégse",
        finalization: "Véglegesítés",
        gross: "Fizetendő összeg: ",
        monthly: "Havonta fizetendő összeg: ",
        max: "Maximálisan levonható összeg:",
        pinkAszf: "Az ismétlődő bankkártyás fizetés (továbbiakban: „Ismétlődő fizetés”) egy, a SimplePay által biztosított bankkártya elfogadáshoz tartozó funkció, mely azt jelenti, hogy a Vásárló által a regisztrációs tranzakció során megadott bankkártyaadatokkal a jövőben újabb fizetéseket lehet kezdeményezni a bankkártyaadatok újbóli megadása nélkül. Az ismétlődő fizetés ún. „eseti hozzájárulásos” típusa minden tranzakció esetében a Vevő eseti jóváhagyásával történik, tehát, Ön valamennyi jövőbeni fizetésnél jóvá kell, hogy hagyja a tranzakciót. A sikeres fizetés tényéről Ön minden esetben a hagyományos bankkártyás fizetéssel megegyező csatornákon keresztül értesítést kap. Az Ismétlődő fizetés igénybevételéhez jelen nyilatkozat elfogadásával Ön hozzájárul, hogy a sikeres regisztrációs tranzakciót követően jelen webshopban (admin.pinkpos.com) Ön az itt található felhasználói fiókjából kezdeményezett későbbi fizetések a bankkártyaadatok újbóli megadása nélkül menjenek végbe. Figyelem(!): a bankkártyaadatok kezelése a kártyatársasági szabályoknak megfelelően történik. A bankkártyaadatokhoz sem a Kereskedő, sem a SimplePay nem fér hozzá. A Kereskedő által tévesen vagy jogtalanul kezdeményezett ismétlődő fizetéses tranzakciókért közvetlenül a Kereskedő felel, Kereskedő fizetési szolgáltatójával (SimplePay) szemben bármilyen igényérvényesítés kizárt. Jelen tájékoztatót átolvastam, annak tartalmát tudomásul veszem és elfogadom.",
        summary: "Az előfizetés lemondásáig, vagy maximum 24 hónapig havonta, maximum 24 alkalommal ",
        summary2: " EUR kerül kiszámlázásra a jelenlegi díjszabás mellett.",
        reqMail: "* Az e-mail mező kitöltése kötelező",
        back: "Vissza",
        success: "Sikeres transzakció",
        error: "Hiba a tranzakció során",
        fraud: "Gyanús tranzakció miatt a fizetés megszakítva",
        timeout: "A tranzakció időtúllépés miatt megszakadt",
        cancelled: "A fizetés megszakítva",
        detailed: "Fizetés részletek",
    },
    back:{
        progress: 'Feldolgozás folyamatban',
        backToHome: "Vissza a kezdőlapra",
        transactionNum: "SimplePay tranzakció azonosító: ",
        success: "Sikeres fizetés",
        backToLicense: "Vissza a vásárlás oldalra",
        timeout: "A tranzakció időtúllépés miatt megszakadt",
        fail: "Sikertelen fizetés",
        failText: "Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét. Amennyiben minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása kapcsán kérjük, szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.",
        cancelled: "A fizetés megszakítva",
        fraud: "Gyanús tranzakció miatt a fizetés megszakítva",
        notAuthorized: "Nem engedélyezett tranzakció",
        reversed: "Zárolt tranzakció visszavonva",

    },
    country:{
        hu: "Magyarország",
        gb: "Nagy Britannia",
        us: "Egyesült Államok",
        af: "Afganisztán",
        ax: "Åland-szigetek",
        al: "Albánia",
        dz: "Algéria",
        as: "Amerikai Szamoa",
        ad: "Andorra",
        ao: "Angola",
        ai: "Anguilla",
        aq: "Antarktisz",
        ag: "Antigua és Barbuda",
        ar: "Argentína",
        am: "Örményország",
        aw: "Aruba",
        au: "Ausztrália",
        at: "Ausztria",
        az: "Azerbajdzsán",
        bs: "Bahama-szigetek",
        bh: "Bahrein",
        bd: "Banglades",
        bb: "Barbados",
        by: "Belarusz",
        be: "Belgium",
        bz: "Belize",
        bj: "Benin",
        bm: "Bermuda",
        bt: "Bhután",
        bo: "Bolívia",
        ba: "Bosznia-Hercegovina",
        bw: "Botswana",
        bv: "Bouvet-sziget",
        br: "Brazília",
        io: "Brit Indiai-óceáni Terület",
        bn: "Brunei",
        bg: "Bulgária",
        bf: "Burkina Faso",
        bi: "Burundi",
        kh: "Kambodzsa",
        cm: "Kamerun",
        ca: "Kanada",
        cv: "Zöld-foki Köztársaság",
        ky: "Kajmán-szigetek",
        cf: "Közép-afrikai Köztársaság",
        td: "Csád",
        cl: "Chile",
        cn: "Kína",
        cx: "Karácsony-sziget",
        cc: "Kókusz (Keeling)-szigetek",
        co: "Kolumbia",
        km: "Comore-szigetek",
        cg: "Kongó",
        cd: "Kongói Demokratikus Köztársaság",
        ck: "Cook-szigetek",
        cr: "Costa Rica",
        hr: "Horvátország",
        cu: "Cuba",
        cy: "Ciprus",
        cz: "Csehország",
        dk: "Dánia",
        dj: "Dzsibuti",
        dm: "Dominika",
        do: "Dominikai Köztársaság",
        ec: "Ecuador",
        eg: "Egyiptom",
        sv: "Salvador",
        gq: "Egyenlítői-Guinea",
        er: "Eritrea",
        ee: "Észtország",
        et: "Etiópia",
        fk: "Falkland-szigetek",
        fo: "Faroe-szigetek",
        fj: "Fidzsi",
        fi: "Finnország",
        fr: "Franciaország",
        gf: "Francia Guyana",
        pf: "Francia Polinézia",
        tf: "Francia Déli és Antarktiszi Területek",
        ga: "Gabon",
        gm: "Gambia",
        ge: "Grúzia",
        de: "Németország",
        gh: "Ghána",
        gi: "Gibraltár",
        gr: "Görögország",
        gl: "Grönland",
        gd: "Grenada",
        gp: "Guadeloupe",
        gu: "Guam",
        gt: "Guatemala",
        gg: "Guernsey",
        gn: "Guinea",
        gw: "Guinea-Bissau",
        gy: "Guyana",
        ht: "Haiti",
        hm: "Heard-sziget és McDonald-szigetek",
        va: "Vatikán",
        hn: "Honduras",
        hk: "Hong Kong",
        is: "Izland",
        in: "India",
        id: "Indonézia",
        ir: "Irán",
        iq: "Irak",
        ie: "Írország",
        im: "Man-sziget",
        il: "Izrael",
        it: "Olaszország",
        jm: "Jamaica",
        jp: "Japán",
        je: "Jersey",
        jo: "Jordánia",
        kz: "Kazahsztán",
        ke: "Kenya",
        ki: "Kiribati",
        kp: "Észak-Korea",
        kr: "Dél-Korea",
        kw: "Kuvait",
        kg: "Kirgizisztán",
        la: "Laosz",
        lv: "Lettország",
        lb: "Libanon",
        ls: "Lesotho",
        lr: "Libéria",
        ly: "Líbia",
        li: "Liechtenstein",
        lt: "Litvánia",
        lu: "Luxemburg",
        mo: "Makaó",
        mk: "Észak-Macedónia",
        mg: "Madagaszkár",
        mw: "Malawi",
        my: "Malajzia",
        mv: "Maldív-szigetek",
        ml: "Mali",
        mt: "Málta",
        mh: "Marshall-szigetek",
        mq: "Martinique",
        mr: "Mauritánia",
        mu: "Mauritius",
        yt: "Mayotte",
        mx: "Mexikó",
        fm: "Mikronézia",
        md: "Moldova",
        mc: "Monaco",
        mn: "Mongólia",
        me: "Montenegró",
        ms: "Montserrat",
        ma: "Marokkó",
        mz: "Mozambik",
        mm: "Mianmar",
        na: "Namíbia",
        nr: "Nauru",
        np: "Nepál",
        nl: "Hollandia",
        nc: "Új-Kaledónia",
        nz: "Új-Zéland",
        ni: "Nicaragua",
        ne: "Niger",
        ng: "Nigéria",
        nu: "Niue",
        nf: "Norfolk-sziget",
        mp: "Északi Mariana-szigetek",
        no: "Norvégia",
        om: "Omán",
        pk: "Pakisztán",
        pw: "Palau",
        ps: "Palesztina",
        pa: "Panama",
        pg: "Pápua Új-Guinea",
        py: "Paraguay",
        pe: "Peru",
        ph: "Fülöp-szigetek",
        pn: "Pitcairn-szigetek",
        pl: "Lengyelország",
        pt: "Portugália",
        pr: "Puerto Rico",
        qa: "Katar",
        re: "Réunion",
        ro: "Románia",
        ru: "Oroszország",
        rw: "Ruanda",
        bl: "Saint-Barthélemy",
        sh: "Szent Ilona, Ascension és Tristan da Cunha",
        kn: "Saint Kitts és Nevis",
        lc: "Saint Lucia",
        mf: "Saint Martin (francia rész)",
        pm: "Saint Pierre és Miquelon",
        vc: "Saint Vincent és a Grenadine-szigetek",
        ws: "Szamoa",
        sm: "San Marino",
        st: "São Tomé és Príncipe",
        sa: "Szaúd-Arábia",
        sn: "Szenegál",
        rs: "Szerbia",
        sc: "Seychelle-szigetek",
        sl: "Sierra Leone",
        sg: "Szingapúr",
        sx: "Sint Maarten (holland rész)",
        sk: "Szlovákia",
        si: "Szlovénia",
        sb: "Salamon-szigetek",
        so: "Szomália",
        za: "Dél-Afrika",
        gs: "Dél-Georgia és Déli-Sandwich-szigetek",
        ss: "Dél-Szudán",
        es: "Spanyolország",
        lk: "Srí Lanka",
        sd: "Szudán",
        sr: "Suriname",
        sj: "Svalbard és Jan Mayen",
        sz: "Szváziföld",
        se: "Svédország",
        ch: "Svájc",
        sy: "Szíria",
        tw: "Tajvan",
        tj: "Tádzsikisztán",
        tz: "Tanzánia",
        th: "Thaiföld",
        tl: "Kelet-Timor",
        tg: "Togo",
        tk: "Tokelau",
        to: "Tonga",
        tt: "Trinidad és Tobago",
        tn: "Tunézia",
        tr: "Törökország",
        tm: "Türkmenisztán",
        tc: "Turks- és Caicos-szigetek",
        tv: "Tuvalu",
        ug: "Uganda",
        ua: "Ukrajna",
        ae: "Egyesült Arab Emírségek",
        um: "Az Amerikai Egyesült Államok Lakatlan Külbirtokai",
        uy: "Uruguay",
        uz: "Üzbegisztán",
        vu: "Vanuatu",
        ve: "Venezuela",
        vn: "Vietnam",
        vg: "Brit Virgin-szigetek",
        vi: "Amerikai Virgin-szigetek",
        wf: "Wallis és Futuna",
        eh: "Nyugat-Szahara",
        cw: "Curaçao",
        bq: "Holland Karib-térség",
        ye: "Jemen",
        zm: "Zambia",
        zw: "Zimbabwe"
    },
}

const signUp  = {
    signUp : {
        login: "Bejelentkezés",
        forgotPassword: "Elfelejtett jelszó",
        registration: "Regisztráció",
        verification: "Ellenőrzés",
        email: "Email",
        password: "Jelszó",
        newPassword: "Új jelszó",
        newPasswordAgain: "Új jelszó ismét",
        passwordDifferentException: "A jelszavak különbözőek",
        verificationCodeRequest: "Ellenőrző kód kérés",
        save: "Mentés",
        givenName: "Keresztnév",
        familyName: "Vezetéknév",
        phone: "Telefon",
        pin: "PIN",
        helloPayPosUser: "HelloPay POS user azonosító",
        helloPayPosUserHint: "HelloPay terminál belépési azonosító. Minimum 4 számjegy",
        phoneHint: "Formátum: +xxxxxxxxxxx pl:+36201234567",
        pinHint: "Csak 4 darab számjegyet tartalmazhat",
        passwordHint: "Min 8 hosszúnak kell lennie. Tartalmaznia kell legalább 1 kisbetűt és 1 nagybetűt az angol abc-ből, 1 számot és 1 speciális karaktert (^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = )",
        verificationCode: "Ellenörző kód",
        verificationCodeSent: "Ellenörző kód elküdve. A kódot az email fiókjában találja.",
        signIn: "Belépés",
        signUp: "Regisztráció",
        readAndAccepted: "Elolvastam és elfogadom az",
        termsAndConditions: "Általános Szerződési Feltételeket.",
        privacyPolicy: "Adatvédelmi szabályzatot.",
        notAuthorizedException: "Rossz felhasználónév vagy jelszó.",
        passwordEmpty: "A jelszó nem lehet üres.",
        passwordPolicy: "A jelszó nem megfelelő. Min 8 hosszúnak kell lennie és tartalmaznia kell legalább 1 kisbetűt és 1 nagybetűt az angol abc-ből, 1 számot és 1 speciális karaktert(^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = ) ",
        accountExist: "Már létezik felhasználó ezzel az email címmel.",
        givenNameEmpty: "Nem lehet üres a keresztnév.",
        familyNameEmpty: "Nem lehet üres a vezetéknév.",
        termsAndConditionsUnaccepted: "Az ÁSZF és az Adatvédelmi Szabályzat elfogadása kötelező",
        phoneNotValid: "Telefonszám formátum nem megfelelő. Helyes formátum: +36xxxxxxxxx",
        pinEmpty: "PIN formátum nem megfelelő. Csak számokat tartamazhat.",
        invalidVerificationCode: "Hibás ellenörző kód, próbálja újra.",
        expiredVerificationCode: "Lejárt az ellenörző kód, próbálja újra.",
        invalidCode: "Érvénytelen kódot adott meg, kérjen újra egy kódot.",
        limitExceededException: "Túllépte a kísérleti korlátot, próbálja meg egy idő után.",
        successfulRegistration: "Sikeres regisztráció. Ellenörző kódot az email fiókjában találja.",
        successfulVerification: "Ellenörzés sikeres. Most már be tud jelentkezni az oldalra."
    }
}


const components = {
    emptyPageOrAccessDenied: {
        empty: "Még nincsenek létrehozott tételei",
        accessDenied: "Nem található az oldal vagy nincs hozzáférése"
    },
    hierarchySingleSelector: {
        selectCompanyOrBu : "Válasszon céget vagy üzleti egységet",
        selectBu : "Válasszon egy üzleti egységet"
    },
    invitePermission : {
        hu: {
            ppos: {
                pposauth: {
                    Privilege: {
                        Admin: {
                            PriceList: "Árlap",
                            Product: "Termékek",
                            BusinessUnit: "Üzleti egységek",
                            Device: "Eszközök",
                            PosHierarchy: "Csoportok kezelése",
                            PriceListToPosSet: "Árlap leküldés",
                            User: "Felhasználók",
                            Pos: "POS-on lévő árlapok",
                            Report: "Jelentések",
                            Company: "Cég",
                            Dashboard: "Vezérlőpult",
                            Invitation: "Meghívás",
                            PosConfig: "POS konfiguráció",
                            PrivilegeEditor: "Jogosultság szerkesztő",
                            WarehouseProduct: "Raktárazás",
                            Disposal: "Selejtezés"
                        },
                        Api: {
                            CashRegister: "Inicializálás",
                            Shift: "Műszak",
                            Workday: "Munkanap",
                            Storno: "Sztorno",
                            Seller: "Eladás"
                        }
                    }
                }
            }
        }
    },
    companySelector: {
        selectCompany: "Válasszon ki egy céget"
    }
}

const hunMessages = mergeDeep(hungarianMessages, missingTranslations, data, signUp, components);


export default hunMessages;